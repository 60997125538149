<template>
  <v-container v-if="!loading" id="companies-table" fluid tag="section">
    <!-- <CompleteProfileBanner v-if="user.type == 0"></CompleteProfileBanner> -->
    <base-material-card
      color="#7733FF"
      icon="mdi-office-building"
      inline
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $tc("company", 2) }}</b>
        </h1>
      </template>
      <v-btn
        color="primary"
        rounded
        v-show="
          user.type == userTypeEnum.Admin ||
          user.type == userTypeEnum.ContentAnalyst ||
          user.type == userTypeEnum.Banker
        "
        @click="add_company()"
        absolute
        fab
        top
        right
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-simple-table>
        <thead>
          <tr>
            <th>{{ $t("name") }}</th>
            <th class="text-center">{{ $t("details") }}</th>
            <th
              class="text-center"
              v-if="user.type == userTypeEnum.Admin && user.adminType == 0"
            >
              {{ $t("allocation_requests") }}
            </th>
            <th class="text-center">
              {{ $t("company_briefing") }}
            </th>
            <th
              v-show="
                user.type == userTypeEnum.Admin ||
                user.type == userTypeEnum.FinancialAnalyst ||
                user.type == userTypeEnum.Banker ||
                user.type == userTypeEnum.ContentAnalyst
              "
              class="text-center"
            >
              {{ $t("profile") }}
            </th>
            <th
              v-show="
                user.type == userTypeEnum.Admin ||
                user.type == userTypeEnum.ContentAnalyst ||
                user.type == userTypeEnum.Banker
              "
              class="text-center"
            >
              {{ $t("edit") }}
            </th>
            <th
              v-show="
                user.type == userTypeEnum.Admin ||
                user.type == userTypeEnum.ContentAnalyst
              "
              class="text-center"
            >
              {{ $t("remove") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(company, index) in companies" :key="index">
            <td>{{ company.Name }}</td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1 secondary"
                @click="company_details(company.CompanyId)"
                min-width="0"
                small
                :data-test="`VehicleTransactions:CompaniesTable:BtnView:${company.CompanyId}`"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </td>
            <td
              class="text-center"
              v-if="user.type == userTypeEnum.Admin && user.adminType == 0"
            >
              <v-btn
                class="px-2 ml-1 secondary"
                @click="company_allocations(company.CompanyId)"
                min-width="0"
                small
              >
                <v-icon small>mdi-cash-multiple</v-icon>
              </v-btn>
            </td>
            <td class="text-center">
              <v-btn
                v-if="company.InvestmentRoundId"
                class="px-2 ml-1 secondary"
                @click="
                  go_to_company_briefing(company.CompanyId, company.BriefingId)
                "
                min-width="0"
                small
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
              <v-btn
                class="px-2 ml-1 secondary"
                v-show="
                  (user.type == userTypeEnum.Admin ||
                    user.type == userTypeEnum.Banker ||
                    user.type == userTypeEnum.ContentAnalyst) &&
                  company.InvestmentRoundId
                "
                @click="edit_round_company_briefing(company.InvestmentRoundId)"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td
              class="text-center"
              v-show="
                user.type == userTypeEnum.Admin ||
                user.type == userTypeEnum.FinancialAnalyst ||
                user.type == userTypeEnum.Banker ||
                user.type == userTypeEnum.ContentAnalyst
              "
            >
              <v-btn
                class="px-2 ml-1 secondary"
                @click="company_briefing(company.CompanyId, 'profile')"
                min-width="0"
                small
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
              <v-btn
                v-if="user.type != 4"
                class="px-2 ml-1 secondary"
                @click="edit_company_briefing(company.ProfileId)"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td
              v-show="
                user.type == userTypeEnum.Admin ||
                user.type == userTypeEnum.Banker ||
                user.type == userTypeEnum.ContentAnalyst
              "
              class="text-center"
            >
              <v-btn
                class="px-2 ml-1 secondary"
                @click="edit_company(company.CompanyId)"
                min-width="0"
                small
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td
              v-show="
                user.type == userTypeEnum.Admin ||
                user.type == userTypeEnum.ContentAnalyst
              "
              class="text-center"
            >
              <v-btn
                class="px-2 ml-1"
                @click="open_delete_dialog(company)"
                color="red"
                min-width="0"
                small
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :obj="delete_obj"
        :name="delete_obj.Name"
        @close="delete_dialog = false"
        @delete="delete_company"
      ></DeleteConfirmationModal>
    </v-dialog>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import { UserTypeEnum } from "@/shared/enums/UserType";
// import CompleteProfileBanner from "@/views/pages/CompleteProfileBanner";

export default {
  name: "DashboardCompaniesTable",

  components: {
    DeleteConfirmationModal,
    // CompleteProfileBanner,
  },

  data: () => ({
    apiService: new ApiService(),
    loading: false,
    dialog: false,
    delete_dialog: false,
    delete_obj: null,
    companies: [],
    user: null,
    roundsEnv: process.env.VUE_APP_ROUNDS,
    userTypeEnum: UserTypeEnum,
  }),
  async created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.loading = true;
    if (
      this.user.type == this.userTypeEnum.Admin ||
      this.user.type == this.userTypeEnum.FinancialAnalyst ||
      this.user.type == this.userTypeEnum.ContentAnalyst ||
      this.user.type == this.userTypeEnum.AlphaAnalyst
    ) {
      await this.get_companies();
    } else if (this.user.type == this.userTypeEnum.Investor) {
      await this.get_investments();
    }
    this.loading = false;
  },
  methods: {
    async get_investments() {
      await this.apiService
        .getRequest("investor/investments")
        .then((resp) => {
          this.companies = JSON.parse(resp).map((x) => x.Company);
        })
        .catch((error) => {});
    },
    async get_companies() {
      await this.apiService
        .getRequest("company/list")
        .then((resp) => {
          this.companies = JSON.parse(resp.message);
        })
        .catch((error) => {});
    },
    company_allocations(companyId) {
      this.$router.push({
        path: `companies/allocations/${companyId}`,
        params: { companyId: companyId },
      });
    },

    go_to_company_briefing: function (company_id, briefing_id) {
      this.$router.push({
        path: `/companies/${company_id}/briefing/${briefing_id}`,
      });
    },

    apply_cnpj_mask: function (cnpj) {
      var i = 0,
        v = cnpj.toString();
      return "##.###.###/####-##".replace(/#/g, (_) => v[i++]);
    },
    format: function (value) {
      var i = 0,
        v = value.toString();
      return "##.###.###/####-##".replace(/#/g, (_) => v[i++]);
    },
    company_details: function (company_id) {
      this.$router.push({
        path: `/companies/${company_id}`,
        params: { company_id: company_id },
        query: { tab: 1 },
      });
    },
    company_briefing: function (company_id, type) {
      this.$router.push({
        path: `companies/briefing/${company_id}/${type}`,
        params: { company_id: company_id, type: type },
      });
    },
    add_company: function () {
      this.$router.push({
        path: `/companies/add`,
      });
    },
    edit_company: function (company_id) {
      this.$router.push({
        path: `/companies/edit/${company_id}`,
        params: { company_id: company_id },
      });
    },
    edit_company_briefing: function (briefingId) {
      this.$router.push({
        path: `/companies/edit/briefing/${briefingId}`,
        params: { briefingId: briefingId },
      });
    },
    edit_round_company_briefing: function (investmentRoundId) {
      this.$router.push({
        path: `/round/details/${investmentRoundId}`,
        params: { investmentRoundId: investmentRoundId },
      });
    },
    open_delete_dialog: function (company) {
      this.delete_obj = company;
      this.delete_dialog = true;
    },
    delete_company: async function () {
      var index = this.companies.indexOf(this.delete_obj);
      this.companies.splice(index, 1);
      await this.apiService
        .deleteRequest(`company/delete/${this.delete_obj.CompanyId}`)
        .then((resp) => {})
        .catch((error) => {});
      this.delete_dialog = false;
    },
  },
};
</script>
