import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c(VContainer,{attrs:{"id":"companies-table","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3 mb-5",attrs:{"color":"#7733FF","icon":"mdi-office-building","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('h1',{staticClass:"h5"},[_c('b',[_vm._v(_vm._s(_vm.$tc("company", 2)))])])]},proxy:true}],null,false,1596273530)},[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(
        _vm.user.type == _vm.userTypeEnum.Admin ||
        _vm.user.type == _vm.userTypeEnum.ContentAnalyst ||
        _vm.user.type == _vm.userTypeEnum.Banker
      ),expression:"\n        user.type == userTypeEnum.Admin ||\n        user.type == userTypeEnum.ContentAnalyst ||\n        user.type == userTypeEnum.Banker\n      "}],attrs:{"color":"primary","rounded":"","absolute":"","fab":"","top":"","right":""},on:{"click":function($event){return _vm.add_company()}}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("mdi-plus")])],1),_c(VSimpleTable,[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("name")))]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("details")))]),(_vm.user.type == _vm.userTypeEnum.Admin && _vm.user.adminType == 0)?_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("allocation_requests"))+" ")]):_vm._e(),_c('th',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("company_briefing"))+" ")]),_c('th',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.user.type == _vm.userTypeEnum.Admin ||
              _vm.user.type == _vm.userTypeEnum.FinancialAnalyst ||
              _vm.user.type == _vm.userTypeEnum.Banker ||
              _vm.user.type == _vm.userTypeEnum.ContentAnalyst
            ),expression:"\n              user.type == userTypeEnum.Admin ||\n              user.type == userTypeEnum.FinancialAnalyst ||\n              user.type == userTypeEnum.Banker ||\n              user.type == userTypeEnum.ContentAnalyst\n            "}],staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("profile"))+" ")]),_c('th',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.user.type == _vm.userTypeEnum.Admin ||
              _vm.user.type == _vm.userTypeEnum.ContentAnalyst ||
              _vm.user.type == _vm.userTypeEnum.Banker
            ),expression:"\n              user.type == userTypeEnum.Admin ||\n              user.type == userTypeEnum.ContentAnalyst ||\n              user.type == userTypeEnum.Banker\n            "}],staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]),_c('th',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.user.type == _vm.userTypeEnum.Admin ||
              _vm.user.type == _vm.userTypeEnum.ContentAnalyst
            ),expression:"\n              user.type == userTypeEnum.Admin ||\n              user.type == userTypeEnum.ContentAnalyst\n            "}],staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("remove"))+" ")])])]),_c('tbody',_vm._l((_vm.companies),function(company,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(company.Name))]),_c('td',{staticClass:"text-center"},[_c(VBtn,{staticClass:"px-2 ml-1 secondary",attrs:{"min-width":"0","small":"","data-test":("VehicleTransactions:CompaniesTable:BtnView:" + (company.CompanyId))},on:{"click":function($event){return _vm.company_details(company.CompanyId)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)],1),(_vm.user.type == _vm.userTypeEnum.Admin && _vm.user.adminType == 0)?_c('td',{staticClass:"text-center"},[_c(VBtn,{staticClass:"px-2 ml-1 secondary",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.company_allocations(company.CompanyId)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-cash-multiple")])],1)],1):_vm._e(),_c('td',{staticClass:"text-center"},[(company.InvestmentRoundId)?_c(VBtn,{staticClass:"px-2 ml-1 secondary",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.go_to_company_briefing(company.CompanyId, company.BriefingId)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-eye")])],1):_vm._e(),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(
                (_vm.user.type == _vm.userTypeEnum.Admin ||
                  _vm.user.type == _vm.userTypeEnum.Banker ||
                  _vm.user.type == _vm.userTypeEnum.ContentAnalyst) &&
                company.InvestmentRoundId
              ),expression:"\n                (user.type == userTypeEnum.Admin ||\n                  user.type == userTypeEnum.Banker ||\n                  user.type == userTypeEnum.ContentAnalyst) &&\n                company.InvestmentRoundId\n              "}],staticClass:"px-2 ml-1 secondary",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.edit_round_company_briefing(company.InvestmentRoundId)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.user.type == _vm.userTypeEnum.Admin ||
              _vm.user.type == _vm.userTypeEnum.FinancialAnalyst ||
              _vm.user.type == _vm.userTypeEnum.Banker ||
              _vm.user.type == _vm.userTypeEnum.ContentAnalyst
            ),expression:"\n              user.type == userTypeEnum.Admin ||\n              user.type == userTypeEnum.FinancialAnalyst ||\n              user.type == userTypeEnum.Banker ||\n              user.type == userTypeEnum.ContentAnalyst\n            "}],staticClass:"text-center"},[_c(VBtn,{staticClass:"px-2 ml-1 secondary",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.company_briefing(company.CompanyId, 'profile')}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-eye")])],1),(_vm.user.type != 4)?_c(VBtn,{staticClass:"px-2 ml-1 secondary",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.edit_company_briefing(company.ProfileId)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e()],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.user.type == _vm.userTypeEnum.Admin ||
              _vm.user.type == _vm.userTypeEnum.Banker ||
              _vm.user.type == _vm.userTypeEnum.ContentAnalyst
            ),expression:"\n              user.type == userTypeEnum.Admin ||\n              user.type == userTypeEnum.Banker ||\n              user.type == userTypeEnum.ContentAnalyst\n            "}],staticClass:"text-center"},[_c(VBtn,{staticClass:"px-2 ml-1 secondary",attrs:{"min-width":"0","small":""},on:{"click":function($event){return _vm.edit_company(company.CompanyId)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1),_c('td',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.user.type == _vm.userTypeEnum.Admin ||
              _vm.user.type == _vm.userTypeEnum.ContentAnalyst
            ),expression:"\n              user.type == userTypeEnum.Admin ||\n              user.type == userTypeEnum.ContentAnalyst\n            "}],staticClass:"text-center"},[_c(VBtn,{staticClass:"px-2 ml-1",attrs:{"color":"red","min-width":"0","small":""},on:{"click":function($event){return _vm.open_delete_dialog(company)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-trash-can")])],1)],1)])}),0)])],1),(_vm.delete_dialog)?_c(VDialog,{model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('DeleteConfirmationModal',{attrs:{"obj":_vm.delete_obj,"name":_vm.delete_obj.Name},on:{"close":function($event){_vm.delete_dialog = false},"delete":_vm.delete_company}})],1):_vm._e()],1):_c(VContainer,[_c(VProgressCircular,{staticStyle:{"margin-left":"50%"},attrs:{"indeterminate":"","size":"70","color":"primary"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }